import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  GoogleHostSelectorService,
  GoogleHostLocation
} from 'src/app/event-activity/services/google-host-selector.service';

@Component({
  selector: 'ea-google-apis-script',
  template: ''
})
export class GoogleApisScriptComponent implements OnInit {
  private readonly apiKey = environment.googleApiKey;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private googleHostSelector: GoogleHostSelectorService) {}

  ngOnInit() {
    this.googleHostSelector.selectHost()
      .subscribe(loc => this.insertScriptElement(loc));
  }

  private getGoogleApisLocation(loc: GoogleHostLocation): string {
    switch (loc) {
      case GoogleHostLocation.China:
        return 'assets/js/googleapis/google.js';
      case GoogleHostLocation.Default:
      default:
        return `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places&language=en-US`;
    }
  }

  private insertScriptElement(loc: GoogleHostLocation) {
    const src = this.getGoogleApisLocation(loc);
    const script = this.renderer.createElement('script');
    this.renderer.setAttribute(script, 'src', src);
    this.renderer.appendChild(this.el.nativeElement, script);
  }
}
